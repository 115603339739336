<template>
  <modal-base :noframe="true">
    <template v-slot:body>
      <iframe
        width="100%"
        height="700vw"
        src="https://www.youtube.com/embed/UNNKLikkHzs"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </template>
    <template v-slot:footer>
      <div class="download" style="color: #55565E;">
        Eğer videoyu görüntüleyemiyorsanız PDF versiyonunu
        <a
          @click="TRACK('Elektronik Imza Yardim Indir')"
          class="blue"
          style="padding: 0 6px;"
          download
          href="/pdf/elektronik_imza_yardim.pdf"
          target="_blank"
        >
          buradan
        </a>
        indirebilirsiniz.
      </div>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  },
  mounted() {
    // _paq.push(["trackEvent", "Basvuru", "Elektronik Imza Video", "Acildi"]);
  },
  methods: {
    TRACK(name) {
      // _paq.push(["trackEvent", "Basvuru", name, "Success"]);
    }
  }
};
</script>

<style scoped>
.modalcontent > div {
  width: 100%;
  padding: 1vw;
  margin: 0 auto;
  display: flex;
}
</style>
