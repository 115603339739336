<template>
  <modal-base class="modalduyuru">
    <template v-slot:body>
      <div class="modalcontent">
        <div class="half left">

<div class="half bg-blue">
    <img src="@/assets/img/hero/calendar.svg" alt="TOBB logo" />
</div>

        </div>

        <div class="formpart duyurutext">

            <h2>Duyuru</h2>

            <p>T.C. Ticaret Bakanlığımızın duyurusuyla hizmet sağlayıcıların Ticari Elektronik İleti Yönetim Sistemi'ne (İYS) kaydolma ve mevcut onaylarını yükleme tarihi ertelenmiştir.</p>

            <p>Buna göre;

<ul style="list-style: disc !important; padding-left:20px;">
  <li style="display: list-item; list-style: disc !important;  ">150 bin adedin üstünde ticari elektronik ileti onayı (telefon numarası ve eposta adresi sayısı toplamı) olan hizmet sağlayıcılar için onay yükleme son tarihi <b>31 Aralık 2020</b> tarihine; bu onayların vatandaş tarafından kontrol edilme son tarihi ise 15 Şubat 2021 tarihine,</li>
  <li style="display: list-item; list-style: disc !important; ">150 bin adet ve altında ticari elektronik ileti onayı (telefon numarası ve eposta adresi sayısı toplamı) olan hizmet sağlayıcılar için onay yükleme son tarihi <b>31 Mayıs 2021</b> tarihine; bu onayların vatandaş tarafından kontrol edilme son tarihi ise 15 Temmuz 2021 tarihine ertelenmiştir.</li>
</ul>
</p>
<p>
  Bakanlığımızın basın açıklamasına şu adresten ulaşabilirsiniz: <br>
  <a href="https://ticaret.gov.tr/haberler/ticari-elektronik-ileti-yonetim-sistemine-iys-iliskin-basin-aciklamasi" class="blue" target="_blank">https://ticaret.gov.tr/haberler/ticari-elektronik-ileti-yonetim-sistemine-iys-iliskin-basin-aciklamasi</a> 
</p>

            <!-- <p>28 Ağustos 2020, 31227 sayılı Resmi Gazete’de yayınlanan <a href="http://www.resmigazete.gov.tr/eskiler/2020/08/20200828-14.htm" class="blue" target="_blank">Ticari İletişim ve Ticari Elektronik İletiler Hakkında Yönetmelikte Değişiklik Yapılmasına Dair Yönetmelik</a> duyurusuyla, hizmet sağlayıcıların onay yükleme tarihi <b>1 Aralık 2020</b> tarihine kadar ertelenmiştir. Bu düzenleme ile alıcılar, yüklenmiş onayları 1 Aralık 2020 tarihinden başlayarak <b>16 Ocak 2021</b> tarihinine kadar kontrol edebileceklerdir. Bu süre içerisinde ret hakkının kullanılmaması halinde İYS’ye aktarılan veriler onaylı sayılacaktır.</p>


            <p>İYS’ye veri yüklemeye başlayan kullanıcıların düzenli biçimde veri yüklemeye devam etmelerini, kaydı devam eden kullanıcıların süreçlerini tamamlayarak veri yüklemeye başlamaları, kaydını henüz yaptırmamış hizmet sağlayıcıların ise en kısa sürede kayıtlarını yaparak veri yükleme aşamasını <b>30 Kasım 2020</b> tarihinden önce tamamlamaları gerekmektedir.</p>

            <p>Kayıt ve veri yükleme işlemleri için <a href="https://iys.org.tr/is-ortaklari" class="blue" >iş ortaklarımıza</a> veya doğrudan bize <a href="https://iys.org.tr/iletisim" class="blue" >ulaşabilirsiniz</a>.</p> -->


            <p>Saygılarımızla bilgilerinize sunulur.</p>
            <p class="blue"><b>İleti Yönetim Sistemi A.Ş.</b></p>

        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase
  }
};
</script>

<style scoped>

.modalcontent > div {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.duyurutext {width: 90% !important; padding-left: 180px  !important;}

.duyurutext p {margin-bottom: 25px;}

.half {
  width: 50% !important;
}
.half p {
  padding-right: 1vw;
}

.half.left {width: 0% !important;}

.half.bg-blue {height: 100%; width: 15% !important;  position: absolute; top: 0; left: 0;}
.half.bg-blue img { display: flex; position: absolute; top: 100px; right: -40%; width: 120% }

.madde ul {padding: 0px 0 10px 20px;  } 
.madde li {padding: 10px 0 10px 2px; list-style: disc; display: list-item;} 


/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {

  .modalcontent > div {
    flex-wrap: wrap;
  }
  .half {
    width: 100% !important;
  }

  .half.left {display: none !important;}

  .duyurutext  {
    width: 90% !important;
    padding-left: 0px !important;
}

ul, li , p {
    line-height: 1.5;
}

.madde ul {padding: 0px 0 1px 0px;  } 
.madde li {padding: 10px 0 10px 2px; list-style: disc; display: list-item;} 

}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {


}
</style>
